.header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
}

.playfair {
  font-family: "Playfair Display", serif;
}

.red {
  color: var(--red);
}
