.social {
  font-size: 2.5rem;
  margin: 5rem 0;
}

.social > a {
  color: inherit;
  cursor: pointer;
  margin: 0 1.5rem;
  vertical-align: middle;
}

.social > a:hover {
  color: var(--red);
}
