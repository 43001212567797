.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
