.contact {
  margin: 5rem 2rem;
  max-width: var(--viewport-7);
}

.contact input,
.contact textarea,
.contact button {
  width: 100%;
  padding: 12px 20px;
}

.contact button {
  margin-top: 2rem;
}

.contact label {
  display: inline-block;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
}

.contact input.error,
.contact textarea.error {
  border: 1px solid var(--error);
}

.contact input.error:focus,
.contact textarea.error:focus,
.contact button:focus {
  outline: none;
}

.contact input:focus,
.contact textarea:focus {
  outline: 1px solid var(--black);
}

.contact button {
  background-color: var(--black);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-weight: 600;
}

.error-text {
  color: var(--error);
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  width: 100%;
}
