@import-normalize;

:root {
  --red: #cf0015;
  --white: #ffffff;
  --black: #181c16;
  --beige: #edeae6;
  --brown: #c5a173;
  --error: red;
  --viewport-4: 480px;
  --viewport-7: 768px;
  --viewport-9: 992px;
  --viewport-12: 1200px;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--white);
  color: var(--black);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--black);
    color: var(--white);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
